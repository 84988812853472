import { wrapGql } from "../_"

export default (a: NuxtAxiosInstance) => ({
  executeQuery: (
    data: {
      query: string,
      variables?: Record<string, any>
    }) => wrapGql(
    a.$post("/graphql", data),
  ),
})
