import getForm from "./modules/form"
import getPartner from "./modules/partner"
import getPerson from "./modules/person"
import getCarPass from "./modules/car-pass"
import getDadata from "./modules/dadata"
import getEvent from "./modules/event"
import getBadge from "./modules/badge"
import getExhibition from "./modules/exhibition"
import getPasswordRecovery from "./modules/password-recovery"
import getLogin from "./modules/login"
import getUser from "./modules/user"
import getChat from "./modules/chat"
import getInstagram from "./modules/instagram"
import getNews from "./modules/news"
import getMentorSession from "./modules/mentor-session"
import getGuest from "./modules/guest"
import getPoll from "./modules/poll"
import getMeeting from "./modules/meeting"
import getBasket from "./modules/basket"
import getHighlight from "./modules/highlight"
import getTag from "./modules/tag"
import getFaq from "./modules/faq"
import getUpload from "./modules/upload"
import getProgram from "./modules/program"
import getPage from "./modules/page"
import getRunetId from "./modules/runet-id"
import getVisit from "./modules/visit"
import getAny from "./modules/any"
import helpers from "./helpers"

// Каждый модуль представляет собой геттер, который генерирует методы
// в runtime (только нужные).
const getApi = (a: NuxtAxiosInstance) => ({
  get form () { return getForm(a) },
  get partner () { return getPartner(a) },
  get person () { return getPerson(a) },
  get carPass () { return getCarPass(a) },
  get dadata () { return getDadata(a) },
  get event () { return getEvent(a) },
  get badge () { return getBadge(a) },
  get exhibition () { return getExhibition(a) },
  get passwordRecovery () { return getPasswordRecovery(a) },
  get login () { return getLogin(a) },
  get user () { return getUser(a) },
  get chat () { return getChat(a) },
  get instagram () { return getInstagram(a) },
  get news () { return getNews(a) },
  get mentorSession () { return getMentorSession(a) },
  get guest () { return getGuest(a) },
  get poll () { return getPoll(a) },
  get meeting () { return getMeeting(a) },
  get basket () { return getBasket(a) },
  get highlight () { return getHighlight(a) },
  get tag () { return getTag(a) },
  get faq () { return getFaq(a) },
  get upload () { return getUpload(a) },
  get program () { return getProgram(a) },
  get page () { return getPage(a) },
  get runetId () { return getRunetId(a) },
  get visit () { return getVisit(a) },
  get any () { return getAny(a) },
  helpers,
})

const plugin: NuxtPlugin = ({ $axios }, inject) => inject("api", getApi($axios))
export default plugin

type Api = ReturnType<typeof getApi>
declare module "vue/types/vue" {
  interface Vue { $api: Api }
}
declare module "@nuxt/types" {
  interface NuxtAppOptions { $api: Api }
  interface Context { $api: Api }
}
declare module "vuex/types/index" {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Store<S> { $api: Api }
}
