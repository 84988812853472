import { wrapGql } from "../_"

export default (a: NuxtAxiosInstance) => ({
  getFormSchema: (form: string, withReadOnlyFields = false) => wrapGql(
    a.$post("/graphql", {
      query: /* gql */ `
        query (
          $form: String!
          $withReadOnlyFields: Boolean!
        ) {
          getFormSchemaByAlias(
            form: $form
            withReadOnlyFields: $withReadOnlyFields
          )
        }
      `,
      variables: {
        form,
        withReadOnlyFields,
      },
    }),
    "getFormSchemaByAlias",
  ),
})
